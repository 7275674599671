<template>
    <div class="secondary-layout">
        <div class="container">
            <div class="row">
                <div class="col-md-10 offset-md-1">
                    <div class="secondary-layout__languages">
                        <languages variant="link" toggle-class="secondary-layout__language-selector" />
                    </div>
                    <div class="secondary-layout__card">
                        <div class="secondary-layout__page">
                            <div class="w-100">
                                <router-view />
                            </div>
                        </div>
                    </div>
                    <p class="secondary-layout__copy">
                        &copy; {{ new Date().getFullYear() }} Accounts. {{ $t('All rights reserved') }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Languages from "@/components/Languages";

export default {
    name: 'secondary-layout',
    components: { Languages },
};
</script>

<style lang="scss">
.secondary-layout {
    position: relative;

    &::before {
        background-color: #000652;
        content: '';
        height: 350px;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &__card {
        background-color: #ffffff;
        border-radius: 20px;
        box-shadow: 0px 0px 30px rgba(#000000, 0.05);
        margin: 32px auto;
        padding: 32px;
        max-width: 852px;

        @media(max-width: 768px) {
            padding: 40px 20px;
            margin-top: 20px;
        }
    }

    &__page {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__languages {
        margin-left: auto;
        margin-top: 32px;
        text-align: right;

        @media(max-width: 768px) {
            margin-top: 20px;
        }
    }

    &__language-selector {
        color: #ffffff !important;
        font-weight: 500 !important;
        text-decoration: none !important;
    }

    &__copy {
        color: #AAAAAA;
        margin-bottom: 30px;
        text-align: center;

        @media(max-width: 768px) {
            text-align: center;
        }
    }
    
    .support-btn {
        border: 2px solid #000652;
        background: white;
        border-radius: 8px;
        padding: 4px;

        text-align: center;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;

        color: #2F2F33;
        width: 240px;

        &:hover {
            background: #fafafa;
            text-decoration: none;
        }
    }
}
</style>
